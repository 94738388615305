import React, { useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, MenuItem, Select } from '@material-ui/core';
import Quagga from 'quagga';
import Webcam from 'react-webcam';
import { BrowserMultiFormatReader, DecodeHintType, Result } from '@zxing/library';
import { start } from 'repl';

interface BarcodeReaderProps {
  open: boolean;
  onClose: () => void;
}

const BarcodeReader: React.FC<BarcodeReaderProps> = ({ open, onClose }) => {
  const webcamRef = useRef<Webcam>(null);
  const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [selectedDevice, setSelectedDevice] = React.useState<string | undefined>(undefined);
  // const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);

  const reader = useRef(new BrowserMultiFormatReader(new Map([[DecodeHintType.ALLOWED_EAN_EXTENSIONS, {}]])));

  const startScanner = (webcamRef: React.RefObject<Webcam>) => {
    if (webcamRef.current === null) return;
    if (webcamRef.current.video === null) return;
    if (webcamRef.current.stream === null) return;
    console.log('startScanner');
        console.log('startScanner');
        reader.current.decodeFromStream( webcamRef.current.stream ,webcamRef.current.video, (result: Result, err) => {
          if (result) console.log(result);
          if (err) {
            console.error(err)
            setTimeout(() => {
              startScanner(webcamRef);
            }, 5000);
          }
        });
      };

  useEffect(() => {
    if (devices.length > 0) {
      console.log(devices);
    }
  }, [devices]);

  //on unmount reset reader
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      setDevices(devices.filter(device => device.kind === 'videoinput'));
    });
    return () => {
      reader.current.reset();
    };
  }, []);

  const handleDeviceChange = (deviceId:string) => {
    setSelectedDevice(deviceId);
    console.log(devices.filter(device => device.deviceId === deviceId)[0]);
    reader.current.reset();
    reader.current = new BrowserMultiFormatReader(new Map([[DecodeHintType.ALLOWED_EAN_EXTENSIONS, {}]]));
    startScanner(webcamRef);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Scan Barcode</DialogTitle>
      <DialogContent>
        <Webcam ref={webcamRef} width={800} height={200} style={{ width: '100%', height: '100%', objectFit: 'fill' }} playsInline muted videoConstraints={{
          width: { ideal: 800 },
          height: { ideal: 600 },
          facingMode: 'environment'
        }} onUserMedia={() => startScanner(webcamRef)} />
        <Button onClick={() => startScanner(webcamRef)}>Close</Button>
        <Select onChange={(e) => handleDeviceChange(e.target.value as string)}>
          {devices.map((device, index) => (
            <MenuItem key={index} value={device.deviceId}>
              {device.label}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
    </Dialog>
  );
};

export default BarcodeReader;