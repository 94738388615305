import { Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';
import React from 'react';
import { Book } from './Bibliotheque';

interface BookCardProps {
    book: Book;
}

const BookCard: React.FC<BookCardProps> = (props) => {
    const { title, description, cover, author } = props.book;
    return (

        <Card key={`${title}_${author}`} sx={{ alignItems: 'center', display: 'flex', textAlign:'center' , height:'290px', textWrap:'pretty' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>

                    <Typography variant="body1" component="div" sx={{fontWeight:'bold'}}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                    <CardMedia
                        sx={{objectFit: 'contain', width: '100%'}}
                        component="img"
                        alt={title}
                        height="140"
                        image={cover}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary">
                            {author}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
}

export default BookCard;