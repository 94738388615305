import React from 'react';
import { Fab, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BookCard from './Book';
import BarcodeReaderComponent from './BarcodeReader';

interface Book {
    title: string;
    description: string;
    author: string;
    cover: string;
}

interface BibliothequeProps {
    books: Book[];
  }

const Bibliotheque:React.FC<BibliothequeProps> = ( props:BibliothequeProps ) => {
    const [openBarcodeReader, setOpenBarcodeReader] = React.useState(false);


    const { books } = props;
    return (
        <Grid container spacing={2}>
            {books && books.map((book) => (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                <BookCard book={book}></BookCard>
                </Grid>
            ))}
            <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: '20px', right: '20px' }} onClick={() => setOpenBarcodeReader(!openBarcodeReader)}>
            <AddIcon />
        </Fab>
        <BarcodeReaderComponent open={openBarcodeReader} onClose={() => setOpenBarcodeReader(!openBarcodeReader)} />
        </Grid>
    );
};

export { Bibliotheque };    export type { Book };

