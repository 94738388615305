import React from 'react';

interface IAuthContext {
    username?: string;
    setUsername?: React.Dispatch<React.SetStateAction<string>>;
    email?: string;
    setEmail?: React.Dispatch<React.SetStateAction<string>>;
    isAuthenticated: boolean;
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = React.createContext<IAuthContext>({
    isAuthenticated: false,
    setIsAuthenticated: function (value: React.SetStateAction<boolean>): void {
        throw new Error('Function not implemented.');
    },
    username: '',
    setUsername: function (value: React.SetStateAction<string>): void {
        throw new Error('Function not implemented.');
    },
    email: '',
    setEmail: function (value: React.SetStateAction<string>): void {
        throw new Error('Function not implemented.');
    }

});

export default AuthContext;
